<template>
  <div class="big-container flex">
    <div class="partie1 w-1/2 bg-oho-blue-clear">
      <img
          src="../../assets/images/png/login.png"
          alt=""
      >
      <div class="bas w-full">
        <div class="w-1/2 h-68 flex justify-center items-center bg-blue-spec">
          <div class="text-center text-c26 font-c6 text-white">
            ERP OHO LES LUNETTES
          </div>
        </div>
        <!--        <div class="w-full flex justify-center items-center h-112 bg-oho-blue">-->
        <!--          <div class="text-center w-1/2 text-c18 font-c3 text-white leading-c-lh-180">-->
        <!--            Gestion intégrée des activités de OHO et ses agences au Bénin-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </div>

    <div class="partie2 w-1/2">
      <div>
        <img
            src="../../assets/images/png/logo.png"
            alt=""
            class="w-36"
        >
      </div>

      <div
          v-if="position < 3"
          class="mt-24"
      >
        <identifiant
            v-if="position === 1"
            @cTrain="retourIdent"
        />
        <agence
            v-if="position === 2"
            :donnes="user"
            :token="token"
        />
        <div
            v-if="error !== null"
            class="pr-20 mt-6 text-red"
        >
          {{ error }}
        </div>
      </div>

      <div
          v-if="position === 3"
          class="mt-12"
      >
        <change-pass
            :donnes="user"
            :token="token"
            @cTrain="retourIdent"
        />
      </div>


      <div
          v-if="position < 3"
          class="text-left mb-10 mt-10"
      >
        <div class="text-c18 font-c4">
          41DEVS | Tous droits réservés
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import identifiant from '@/component/cdevs/sign/identifiant'
import agence from '@/component/cdevs/sign/agence'
import changePass from '@/component/cdevs/sign/changePass'

export default {
  name: 'Login',
  components: {
    // ScaleLoader
    identifiant,
    agence,
    changePass
  },
  data () {
    return {
      position: 1,
      user: null,
      token: null,
      authorize: ['SUPRA_ADMIN', 'DIRECTION', 'ACCOUTING', 'ACCOUNTING'],
      error: null
    }
  },
  methods: {
    voir () {
      if (this.password === 'password') {
        this.password = 'text'
      } else {
        this.password = 'password'
      }
    },

    save () {
      this.charge = true
      this.error = null
    },

    retourIdent(answer){
      this.error = null
      this.user = this.parseJwt(answer)

      this.token = answer
      if (answer) {
        if (this.user.passwordUpdated === false){
          this.position = 3
        } else {
          if (this.authorize.indexOf(this.user.type) !== -1) {
            this.$store.dispatch('saveToken', this.token)
            window.location.replace(window.location.origin + '/caisses');
          } else {
            this.error = "Vous n'êtes pas autoriser à accéder à la comptabilité"
          }
        }
      }
    },

    parseJwt (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''));
      return JSON.parse(jsonPayload)
    }
  }
}

</script>

<style lang="scss" scoped>
@import "../../assets/styles/sass/variables";
.big-container{
  height: 100%;
  width: 100%
}
.partie1{
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position:center;
  //background-image: url('../../assets/images/png/login.png');
}
.aa{
  background-color: #3E3D53;
}
img{
  height: 100%;
  // object-fit: contain;
}
.bas{
  position: absolute;
  bottom: 0;
}
.partie2{
  width:50%;
  height: 100vh;
  background-color: white;
  padding: 40px 100px 100px 70px;
  max-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

@media screen and (max-width: 1400px) and (min-width: 800px){
  .partie2{
    padding: 40px 30px 100px 60px
  }
}
</style>
